@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  min-height: 100vh;
  width: 100vw;
  background-color: #282c34;
}

@media (min-width: 1366px) {
  body {
    overflow: auto;
  }
}

div#root {
  height: 100%;
  width: 100%;
}

.cs_tabs .ant-tabs-nav-wrap {
  padding: 0 1rem;
}

.cs_tabs .ant-tabs-content-holder {
  height: 78vh;
  overflow: auto;
}

@media (max-height: 991px) {
  .cs_tabs .ant-tabs-content-holder {
    height: 70vh;
  }
}

.cs_tree .ant-tree-list-holder {
  background: #F3F3F3;
}

.cs_tree .ant-tree-list {
  overflow: auto;
  height: 38vh;
  background: #F3F3F3;
}

.cs_tree .ant-tree-list-holder .ant-tree-treenode {
  padding: 0;
}

.cs_tree .ant-tree-icon__customize .anticon {
  position: relative;
  bottom: 0.2rem;
}

.cs_tree .ant-tree-switcher .anticon {
  position: relative;
  bottom: 0.1rem;
}

.btn-icon.ant-btn .anticon {
  position: relative;
  bottom: 0.15rem;
}

.cs_carousel-modal .ant-modal-body{
  padding-left: 2rem;
  padding-right: 2rem;
}

.cs_input.ant-input,
.cs_input.ant-btn {
  padding: 0.25rem 0.6875rem !important;
  border-radius: 0.125rem;
}

.cs_input.ant-btn {
  font-size: 0.875rem;
}

.login_form .ant-form-item-explain-error {
  display: flex;
}

.login_form .ant-form-item-explain-error {
  display: flex;
  font-weight: 400 !important;
}

.borderless_input.ant-input {
  border: none;
  text-align: left;
  padding-left: 0;
}

.borderless_input.ant-input:hover {
  background-color: rgb(250, 250, 250);
}

.cs_template-bg {
  background-color: rgb(226 232 240);
}

.cs_preview-img .ant-image-preview-img-wrapper {
  display: flex;
  justify-content: center;
}

.cs_preview-img .ant-image-preview-img-wrapper::before {
  display: none;
}

.btn-default_icons.ant-btn {
  background: transparent !important;
  border: 0;
  box-shadow: none;
  padding: 0;
}

.btn-default_icons.ant-btn .anticon-file-add svg,
.btn-default_icons.ant-btn .anticon-user-add svg,
.btn-default_icons.ant-btn .anticon-usergroup-delete svg,
.btn-default_icons.ant-btn .anticon-usergroup-add svg {
  font-size: 1.125rem;
}
