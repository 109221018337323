.users-actions_modal .ant-modal-content {
  border: 2px solid #000;
  border-radius: 1.875rem;
}

.users-actions_modal .ant-modal-content .ant-modal-close-x span {
  position: relative;
  bottom: 0.75rem;
}

.users-actions_modal .ant-modal-header {
  border-top-right-radius: 1.875rem;
  border-top-left-radius: 1.875rem;
  border-bottom-width: 0;
  padding: 0.375rem 1.5rem 1rem;
}

.users-actions_modal .ant-modal-footer {
  border-top-width: 0;
}

.users-actions_modal .ant-input,
.cs_uploader-btn.ant-btn  {
  border-color: #000;
  padding: 0 0.6875rem !important;
  text-align: center !important;
  border-radius: 0.25rem !important;
}

.cs_uploader .cs_uploader-main{
  flex-flow: column;
}

.cs_uploader .ant-upload {
  width: 100%;
}

.cs_uploader-btn.ant-btn {
  height: 1.5rem;
  bottom: 0.1875rem;
  width: 100%;
}

.cs_uploader-btn.ant-btn svg {
  position: relative;
  bottom: 0.125rem;
}

.cs_uploader-btn.ant-btn span:last-child {
  font-size: 0.875rem;
}

.cs_uploader-btn-2.ant-btn {
  border: 0 !important;
  color: black !important;
  background: transparent;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  gap: 0.125rem;
}

.cs_uploader-btn-2.ant-btn > .anticon + span {
  margin-left: 0;
}

.users-actions_modal .ant-input::placeholder,
.cs_uploader-btn.ant-btn {
  color: #000;
  text-align: center;
}

.users-actions_modal .ant-btn {
  padding: 0;
  text-align: center !important;
  border-radius: 0.625rem;
}

.btn-br-black.ant-btn {
  border-color: #000;
}

.login_btn.ant-btn {
  font-size: 0.75rem;
  padding: 0rem 0.5rem;
  border-color: #000;
  text-align: center !important;
  border-radius: 0.625rem;
}
