.drop-menu.ant-menu {
  border: 0;
  width: 15rem;
  box-shadow: 0 0.375rem 0.375rem #00000029 !important;
}

.drop-menu .ant-menu-submenu-active,
.drop-menu
  .ant-menu-submenu:hover
  > .ant-menu-submenu-title
  > .ant-menu-submenu-arrow,
.drop-menu .ant-menu-item:hover,
.drop-menu .ant-menu-item-active,
.drop-menu .ant-menu-submenu-selected,
.drop-menu .ant-menu-submenu-open .ant-menu-submenu-active,
.drop-menu .ant-menu-submenu-open .ant-menu-submenu-title:hover,
.drop-menu.ant-menu:not(.ant-menu-horizontal) .ant-menu-item-selected,
.drop-menu .ant-menu-item-selected {
  color: rgba(0, 0, 0, 0.85) !important;
  background: #ecebeb !important;
}

.drop-menu.ant-menu li {
  padding: 0;
  margin: 0;
}

.drop-menu.ant-menu li .ant-btn {
  padding: 0 16px;
  width: 100%;
  text-align: left;
}

.item_with_close_btn {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding: 0 16px;
}

.move_to_btn.ant-btn {
  padding: 0 !important;
}
.close_icon.ant-btn {
  padding: 8px 0 0 0 !important;
  display: flex;
  justify-content: flex-end;
  width: 1.25rem !important;
}
