@import "~antd/dist/antd.css";

.App {
  text-align: center;
  min-height: 100%;
  width: 100%;
  background-color: #f3f3f3;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.error-message {
  color: #ff4d4f !important;
  font-size: 14px !important;
  clear: both;
  min-height: 2.4rem;
  padding-top: 0;
  color: rgba(0, 0, 0, 0.45);
  font-size: 1.4rem;
  line-height: 1.5715;
  -webkit-transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
  transition: color 0.3s cubic-bezier(0.215, 0.61, 0.355, 1);
}
