.cs_divider.ant-divider {
  margin: 0.5rem 0 0 0;
  border-top-color: #000;
}

.workspace {
  min-height: 78vh;
  overflow: auto;
}

.workspace.ant-list-bordered {
  background: #fff;
  border-color: #000;
  border-top: 0;
  border-radius: 0.375rem;
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.workspace.ant-list .ant-list-item {
  padding: 0.25rem 1rem !important;
  height: 2.5rem !important;
}

.workspace .ant-list-item:hover {
  background: #fafafa;
}

.workspace .ant-list-item:only-child {
  border-top: 0;
}

.workspace .ant-list-item:not(:first-child),
.workspace .ant-list-item:last-child {
  border-top: 0.0625rem dashed gray;
}

.workspace .ant-list-item.active {
  background: #d8eaf8;
}

.full_btn .ant-btn {
  width: 100%;
}

.dropdown_br {
  border: 0.0625rem solid;
  text-align: center;
}

.dropdown_br .ant-dropdown-menu-item {
  text-align: center;
}

.action_sidebar-btn.ant-btn {
  border-radius: 0.125rem;
}

.cs_add-file-modal .cs_add-file-modal--table {
  height: 60vh;
  overflow-y: auto;
}

.cs_add-file-modal .cs_add-file-modal--table .ant-table-wrapper {
  width: 100%;
}
