.btn-br-black.ant-btn {
  border-color: #000;
}

.login_btn.ant-btn {
  font-size: 0.75rem;
  padding: 0rem 0.5rem;
  border-color: #000;
  text-align: center !important;
  border-radius: 0.625rem;
}
