.workspace-tree .ant-tree-switcher {
  display: none;
}

.workspace-tree .ant-tree-node-content-wrapper {
  display: flex;
}

.workspace-tree .ant-tree-treenode {
  height: 27px;
}
